/*
 * Breakpoint
 */
$breakpoints: (
  'xxx-small': 320px,
  'xx-small': 375px,
  'x-small': 480px,
  'small': 768px,
  // 'medium': 840px,
  // 'large': 960px,
  'x-large': 1024px,
  // 'xx-large': 1280px,
  'xxx-large': 1440px,
  // 'high-quality': 1700px,
  'x-high-quality': 1920px,
) !default;

$sidenav-breakpoint: 'x-large';

$home-contact-breakpoint: 'small';
