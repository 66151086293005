/* Default font size for the body */
html,
body {
  height: 100%;
  font-family: get-font('primary');
  color: $black;
  font-size: 16px;
  $font-base: 16px;
}

/**
 * Set basic elements and clases sizes and styles
 */
h1,
.heading-display1 {
  font-family: get-font('secondary');
  font-weight: 900;
  color: $black;
}
.heading-display2 {
  font-family: get-font('secondary');
  font-weight: 600;
  line-height: calc-size(54px);
  text-align: center;
  color: $black;
}
.heading-display3 {
  font-family: get-font('secondary');
  font-weight: 500;
  color: $dark-blue;
}
.heading-display4 {
  font-family: get-font('secondary');
  font-weight: 600;
  line-height: calc-size(45px);
  color: $dark-blue;
}
h2 {
  font-family: get-font('secondary');
  font-weight: 600;
}
h3 {
  font-family: get-font('secondary');
  font-weight: 600;
}
h4 {
  font-family: get-font('secondary');
  font-weight: 600;
}
p,
li,
table,
td,
tr {
  font-family: get-font('primary');
  font-weight: 400;
}
p,
a {
  line-height: 1.5;
}
.text-variant1 {
  font-family: get-font('primary');
  font-weight: 400;
}
.text-variant2 {
  font-family: get-font('secondary');
  font-weight: 400;
}
.text-caption {
  &--semibold {
    font-weight: 700;
  }
}
.link {
  color: $dark-blue;
  &:hover {
    opacity: 0.5;
  }
}
.link--no-underline {
  color: $dark-blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

/*
 * Set font base for each breakpoint
 */
@each $breakpoint, $value in $breakpoints {
  @include set-breakpoint($breakpoint) {
    /**
       * Set basic elements and clases sizes and styles
       */
    h1,
    .heading-display1 {
      font-size: calc-size(get-font-size($breakpoint, 'h1'));
    }
    .heading-display2 {
      font-size: calc-size(get-font-size($breakpoint, 'display2'));
    }
    .heading-display3 {
      font-size: calc-size(get-font-size($breakpoint, 'display3'));
    }
    .heading-display4 {
      font-size: calc-size(get-font-size($breakpoint, 'display4'));
    }
    h2 {
      font-size: calc-size(get-font-size($breakpoint, 'h2'));
    }
    h3 {
      font-size: calc-size(get-font-size($breakpoint, 'h3'));
    }
    h4 {
      font-size: calc-size(get-font-size($breakpoint, 'h4'));
    }
    .h4 {
      font-size: calc-size(get-font-size($breakpoint, 'h4'));
    }
    p,
    li,
    a {
      font-size: calc-size(get-font-size($breakpoint, 'text'));
    }
    .text-variant1 {
      font-size: calc-size(get-font-size($breakpoint, 'variant1'));
    }
    .text-variant2 {
      font-size: calc-size(get-font-size($breakpoint, 'variant2'));
    }
    .text-caption {
      font-size: calc-size(get-font-size($breakpoint, 'caption'));
    }
    // Layout
    header.principal-header {
      p,
      li,
      a {
        font-size: calc-size(get-font-size($breakpoint, 'header'));
      }
    }
    footer.footer {
      p,
      li,
      a {
        font-size: calc-size(get-font-size($breakpoint, 'footer'));
      }
    }
  }
}


a[href="/apply-now?PID=8888"] {
  text-decoration: none;
}