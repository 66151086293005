/*
 * Shadow values for elements
 * 
 */
 $shadow: (
  'x-small': 4px 4px 8px rgba(0, 0, 0, 0.2),
  'small': 4px 4px 8px rgba(0, 0, 0, 0.2),
  'medium': 4px 4px 8px rgba(0, 0, 0, 0.2),
  'large': 4px 4px 8px rgba(0, 0, 0, 0.2)
)!default;
