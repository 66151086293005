/*
 * Spacing values
 * Spacing values are static and don’t change based on platform scale. Different spacing values can be used for different sizes and scales as needed.
 * See: https://spectrum.adobe.com/page/spacing/
 */
$spacing: (
  '50': 2px,
  '75': 4px,
  '85': 6px,
  '100': 8px,
  '150': 12px,
  '200': 16px,
  '300': 24px,
  '400': 32px,
  '450': 36px,
  '500': 40px,
  '600': 48px,
  '650': 56px,
  '700': 64px,
  '750': 72px,
  '800': 80px,
  '900': 96px,
  '1000': 112px,
)!default;
