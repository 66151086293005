.page {
  &__content {
    box-sizing: border-box;

    .page:not(.page--show-navigation) & {
      min-height: calc(
        100vh - clamp(
            $header-min-height,
            $header-preferred-height,
            $header-max-height
          ) - $footer-mobile-not-show-navigation
      );
    }
    .page.page--show-navigation & {
      min-height: calc(
        100vh - clamp(
            $header-min-height,
            $header-preferred-height,
            $header-max-height
          ) - $footer-mobile-show-navigation
      );
    }

    @include set-breakpoint('small') {
      .page:not(.page--show-navigation) & {
        min-height: calc(
          100vh - $footer-desktop-not-show-navigation - clamp(
              $header-min-height,
              $header-preferred-height,
              $header-max-height
            )
        );
      }
      .page.page--show-navigation & {
        min-height: calc(
          100vh - clamp(
              $header-min-height,
              $header-preferred-height,
              $header-max-height
            ) - $footer-desktop-show-navigation
        );
      }
    }
  }
}
