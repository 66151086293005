.mat-dialog-container {
  max-width: 780px;
  max-height: 95vh !important;
  padding: 0 !important;

}
.mat-dialog-content {
  // Hide scroll bar for desktop devices
  overflow-y: hidden !important;
  .content__docs & {
    overflow-y: auto !important;
  }
}
.dialog {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: calc-size(map-get($spacing, '700'));
    &--required-docs {
      padding-top: calc-size(map-get($spacing, '200'));
    }
    .input--radio-buttons {
      font-size: 20px;
      color: $dark-blue;
      display: flex;
      flex-direction: column;
    }
  }
  &__image,
  &__spinner {
    display: block;
    max-width: 100px;
    margin-bottom: calc-size(map-get($spacing, '200'));
  }

  &__button {
    button.button {
      font-weight: 400;
    }
  }

  &__buttons {
    margin-top: calc-size(map-get($spacing, '300'));
    width: 100%;
    
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: calc-size(map-get($spacing, '300'));

    button.button {
      min-width: 225px;
    }
  }

  &__form {
    margin-block: calc-size(map-get($spacing, '150'));
    .input--password,
    .input--email {
      min-width: 400px;
    }
  }

  &__button--close {
    position: absolute;
    top: 0;
    right: 0;
    width: fit-content;
    background-color: transparent;
    font-size: 14px;
    padding: 1rem;
    border: none;
    color: $dark-blue;
    cursor: pointer;
    
    .mat-icon {
      height: 18px;
      width: 18px;
    }
  }
}

@include set-breakpoint('small', max) {
  .mat-dialog-content {
    overflow-y: scroll !important;
  }
  .dialog {
    &__content {
      padding: calc-size(map-get($spacing, '300'));
      &--ssn-validation {
        padding: calc-size(map-get($spacing, '700'));
      }
    }
    &__image {
      max-width: 80px;
    }
  }
}

@include set-breakpoint('x-small', max) {
  .dialog {
    &__content {
      &--ssn-validation {
        padding-inline: calc-size(map-get($spacing, '300'));
      }
      &--required-docs {
        .mat-dialog-content {
          padding-inline: calc-size(map-get($spacing, '400'));
        }
      }
    }
    &__form {
      width: 100%;  
      .input--password,
      .input--email {
        min-width: 100% !important;
      }
    }
  }
}

@include set-breakpoint('x-small') {
  .dialog {
    &__buttons {
      flex-direction: row;
      justify-content: center;
      gap: calc-size(map-get($spacing, '400'));

      /* two items */
      ui-button:first-child:nth-last-child(2),
      ui-button:first-child:nth-last-child(2) ~ ui-button {
        flex: 1 1 0;
        max-width: 170px;
      }
  
      button.button {
        min-width: unset;
        width: 100%;
      }
    }
  }
}

.dark-backdrop {
  background-color: rgba(0,0,0,0.8);
}

.view--blurred {
  filter: blur(4px);
}

.view--blocked {
  pointer-events: none;
}
