/**
 * Menu size
 */
.mat-icon.mat-white {
  fill: $white;
}

.mat-icon.mat-black {
  fill: $black;
}

.icon-primary {
  color: $brand-blue;
}
/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
.icon-primary svg {
  fill: $brand-blue;
}

.button-upload {
  .icon-upload {
    width: 100%;
  }
}

.mat-icon.icon-brand-facebook-outline,
.mat-icon.icon-brand-linkedin-outline {
  &.mat-black {
    svg path {
      stroke: $black;
    }
  }
  &.mat-white {
    svg path {
      stroke: $white;
    }
  }
}

.mat-icon.icon-brand-instagram-outline {
  &.mat-black {
    svg path {
      fill: $black;
    }
  }
  &.mat-white {
    svg path {
      fill: $white;
    }
  }
}


.button-upload:hover > button.button:not(.button--disabled) {
  .mat-icon.icon-upload {
    svg path {
      stroke: $black;
    }
  }
}
