/* You can add global styles to this file, and also import other style files */

@import './app/@ui/core/globals.scss';

// @import '../../shared/styles/material.scss';

/* You can add global styles to this file, and also import other style files */
// @import './assets/scss/index';

// input[type='submit'] {
//   display: none;
// }

// :root {
//   scroll-behavior: smooth;
// }

// .privacy-policy-link {
//   &:hover {
//     text-decoration: underline;
//   }
// }

// a:not([href]):not([tabindex]) {
//   color: #007ad9;
//   text-decoration: none;
// }
