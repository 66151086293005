/**
 * Styles to manage header on fixed position
 */
$header-min-height: 55px;
$header-preferred-height: 10vw;
$header-max-height: 90px;

/**
 * Sizes to manage footer on absolute position 
 */
$footer-mobile-show-navigation: 400px;
$footer-desktop-show-navigation: 340px;
$footer-mobile-not-show-navigation: 320px;
$footer-desktop-not-show-navigation: 320px;

/**
 * Error message
 */
$error_height: 22px;
