$input-margin-y: 1.2em;
$input-margin-x: 1.4em;

$label-margin-base: 1em;
$label-margin-x: calc-size(map-get($spacing, '400'));

/* Remove autocomplete highlight for all input fields */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px transparent inset;
}

.input__wrapper {
  width: 100%;
  
  &.--no-top {
    &.mat-form-field-appearance-outline {
      .mat-form-field-outline {
        top: 0;
      }
    }
  }

  &.mat-form-field.mat-form-field-disabled .mat-form-field-flex {
    background: #6B6B6B1A;
  }

  /**
   * Icon
   */
  div.mat-form-field-flex {
    align-items: center;
  }

  &.mat-form-field-appearance-outline .mat-form-field-suffix {
    &:has(.mat-datepicker-toggle, .icon-input-error, .icon-input-correct) {
      top: 0 !important;
    }
    .mat-mdc-icon-button {
      text-align: right;
      color: $dark-blue;
    }
  }

  // SSN Field
  &--ssn.mat-form-field-appearance-outline .mat-form-field-suffix {
    &:has(.mat-datepicker-toggle, .icon-input-error, .icon-input-correct) {
      display: flex;
      gap: .5rem;
    }
    .icon--eye {
      cursor: pointer;
    }
  }

  // DV Number

  &--dv-number.mat-form-field-appearance-outline .mat-form-field-suffix {
    &:has(.mat-datepicker-toggle, .icon-input-error, .icon-input-correct) {
      display: flex;
      gap: .5rem;
    }
    .icon--eye {
      cursor: pointer;
    }
  }

  /**
   * Size
   */
  &.mat-form-field-appearance-outline:not(.mat-form-field-disabled)
    .mat-form-field-flex {
    min-height: 58px; // Same as design
  }

  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label,
  &.mat-form-field-appearance-outline.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(calc(-#{$input-margin-y} - 5px))
      translateX(calc($label-margin-x - $label-margin-base)) scale(0.75);
  }

  .mat-form-field-label {
    top: calc($input-margin-y + $font-base) !important;
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: $input-margin-y 0;
      border-top: none;
    }

    .mat-form-field-flex {
      padding: 0 $input-margin-x 0 $input-margin-x;
    }
  }

  .mat-form-field-outline {
    .mat-form-field-outline-start {
      width: calc($label-margin-base + $label-margin-x) !important;
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-outline {
      top: -0.75em;
    }

    .mat-form-field-outline-start {
      border-radius: map-get($round-corners, 'medium') 0 0
        map-get($round-corners, 'medium');
    }

    .mat-form-field-outline-end {
      border-radius: 0 map-get($round-corners, 'medium')
        map-get($round-corners, 'medium') 0;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }

  /**
   * STATES
   */

  /**
   * Normal
   */
  &.mat-form-field-appearance-outline .mat-form-field-label,
  &.mat-form-field-appearance-outline .mat-form-field-label span,
  &.mat-form-field-appearance-outline .mat-form-field-outline {
    color: $gray;
  }

  /**
   * Disabled
   */
  &.mat-form-field-disabled {
    cursor: not-allowed;
    .mat-input-element {
      cursor: not-allowed;
    }
  }

  &.mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-flex {
    background-color: $gray-input-disabled;
  }

  /**
   * On hover
   */
  //  &.mat-form-field-appearance-outline:hover .mat-form-field-label,
  &.mat-form-field-appearance-outline:hover:not(.mat-form-field-disabled) .mat-form-field-outline span,
  &.mat-form-field-appearance-outline:hover:not(.mat-form-field-disabled) .mat-form-field-outline {
    color: $black;
  }

  /**
   * On focus
   */
  &.mat-form-field-appearance-outline.mat-focused {
    .mat-form-field-label,
    .mat-form-field-label span {
      color: $black;
    }
    .mat-form-field-outline {
      color: $brand-blue;
    }
  }

  /**
   * On error response
   * This color is setted on material theming.
   */
  &.mat-form-field-appearance-outline.ng-invalid:not(.ng-untouched)
    .mat-form-field-label,
  &.mat-form-field-appearance-outline.ng-invalid:not(.ng-untouched)
    .mat-form-field-label
    span,
  &.mat-form-field-appearance-outline.ng-invalid:not(.ng-untouched)
    .mat-form-field-outline {
    color: $red;
  }

  /**
   * On correct response
   */
  &.mat-form-field-appearance-outline.ng-valid:not(.ng-untouched)
    .mat-form-field-label,
  &.mat-form-field-appearance-outline.ng-valid:not(.ng-untouched)
    .mat-form-field-label
    span,
  &.mat-form-field-appearance-outline.ng-valid:not(.ng-untouched)
    .mat-form-field-outline {
    color: $green;
  }

  /* Font color */
  .mat-input-element {
    color: $gray !important;
  }
}

.foundation-form--theme-dark {
  .input__wrapper {
    &.mat-form-field-appearance-outline:not(.mat-form-field-disabled)
      .mat-form-field-flex {
      background-color: $white;
      border-radius: 10px;
    }

    &.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
      transform: translateY(135%) !important;
      .mat-hint {
        color: $white;
      }
    }
  }

  .checkbox {
    .mat-checkbox-inner-container {
      border: 1px solid $white;
      border-radius: 2px;
    }

    .mat-checkbox-label {
      color: $white;
    }

    a {
      color: $brand-blue;
    }
  }
}

/**
 * Styles for dark background
 */

.section--theme-dark {
  .input__wrapper {
    /**
     * On error response
     * This color is setted on material theming.
     */
    &.mat-form-field-appearance-outline.ng-invalid:not(.ng-untouched)
      .mat-form-field-label,
    &.mat-form-field-appearance-outline.ng-invalid:not(.ng-untouched)
      .mat-form-field-label
      span,
    &.mat-form-field-appearance-outline.ng-invalid:not(.ng-untouched)
      .mat-form-field-outline {
      color: $red-400;
    }
  }
}
