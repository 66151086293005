.mat-stepper-horizontal {
  $size-step-icon: 32px;
  .mat-horizontal-content-container {
    padding: 0;
    background-color: $white;
  }
  .mat-step-header {
    // Disabled only for testing purposes
    pointer-events: none;
  }
  .mat-horizontal-stepper-header {
    max-width: $size-step-icon;
    padding-inline: 0 !important;
    overflow: visible;
    .mat-step-icon {
      height: $size-step-icon;
      width: $size-step-icon;
      background-color: $gray;
      &-selected,
      &-state-done {
        background-color: $dark-blue;
      }
    }
  }
  .mat-step-text-label {
    font-size: calc-size(get-font-size('x-high-quality', 'stepper'));
    color: $dark-blue;
    min-width: 100px;
    position: absolute;
    left: 0;
    transform: translate(-35%, 0);
  }
  .mat-stepper-horizontal-line {
    border-top: solid 2px $gray;
  }
  // Highlight horizontal line after step is completed
  .mat-horizontal-stepper-header:has(.mat-step-icon-state-done) {
    & + .mat-stepper-horizontal-line {
      border-top: solid 2px $dark-blue;
    }
  }     
  .mat-horizontal-stepper-header:has(.mat-step-icon-selected) {
    & + .mat-stepper-horizontal-line {
      border-image: linear-gradient(
          to right,
          $dark-blue 0%,
          $dark-blue 50%,
          $gray 50%,
          $gray 100%
        )
        10;
    }
  }

  // @include set-breakpoint('small', max) {
  .mat-horizontal-stepper-header-container {
    padding-inline: calc-size(map-get($spacing, '400'));
  }
  // }
  @include set-breakpoint('x-small', max) {
    .mat-horizontal-stepper-header-container {
      display: none;
    }
  }

  // Stepper width
  &.mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
    max-width: 850px;
    width: 70%;
    margin: 0 auto;
  }

  // Stepper content margin top
  .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
    @extend .section--margin-top;
  }

  // Checkbox ripple
  .mat-horizontal-content-container {
    $ripple-spacing: 15px;
    padding-left: $ripple-spacing;
    padding-right: $ripple-spacing;
    margin-left: -#{$ripple-spacing};
    margin-right: -#{$ripple-spacing};
  }
}

.stepper--overflow-visible {
  .mat-horizontal-stepper-wrapper {
    .mat-horizontal-content-container {
      overflow: visible;
    }
  }
}

.step-indicator {
  display: none;
  &__text {
    .text__title {
      color: $dark-blue;
      font-size: get-font-size('small', 'h3');
    }
    .text__next {
      color: $black;
      font-size: get-font-size('small', 'text');
      margin-top: calc-size(map-get($spacing, '100'));
    }
  }
}
@include set-breakpoint('x-small', max) {
  .step-indicator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: calc-size(map-get($spacing, '500'));
    // Step indicator gray color
    .mat-progress-spinner {
      height: auto;
      &::before {
        content: '';
        position: absolute;
        width: 90%;
        height: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border: 4px solid $gray-250;
        z-index: 0;
      }
    }
  }
}
@include set-breakpoint('xx-small') {
  .mat-stepper-horizontal {
    width: 100%;
  }
}
