/**
 * Margin sizes given specific breakpoints
 */
$margin-sizes: (
  'xxx-small': (
    'marign-left': calc(calc-size(map-get($spacing, '300')) + env(safe-area-inset-left)),
    'marign-right': calc(calc-size(map-get($spacing, '300')) + env(safe-area-inset-right)),
  ),
  'small': (
    'marign-left': calc(calc-size(36px) + env(safe-area-inset-left)),
    'marign-right': calc(calc-size(36px) + env(safe-area-inset-right)),
  ),
  'xxx-large': (
    'marign-left': auto,
    'marign-right': auto,
    'max-width': $max-content-width,
  ),
) !default;

@each $breakpoint, $value in $margin-sizes {
  @include set-breakpoint($breakpoint) {
    $marign-left: map-get($value, 'marign-left'); // Required
    $marign-right: map-get($value, 'marign-right'); // Required
    $max-width: map-get($value, 'max-width'); // Optional
    html,
    body {
      header.principal-header > *,
      .page__content,
      .section__content,
      .section__apply-now > div,
      .hero__content,
      .footer__content,
      .home .section-group--centered {
        margin: 0 $marign-left 0 $marign-right;
        @if ($max-width) {
          max-width: $max-width;
        }
      }
    }
  }
}

.section--margin-top {
  padding-top: calc-size(map-get($spacing, '500'));
  @include set-breakpoint('small') {
    padding-top: calc-size(map-get($spacing, '600'));
  }
}
