/**
 * Mixin to import all font files for compatibility with various browsers
 */
 
 @mixin font-face($font-name, $font-path, $font-weight: 'Regular', $font-style: normal, $font-display: swap) {
  @font-face {
    font-family: $font-name;
    font-weight: get-font-weight($font-weight);
    font-style: $font-style;
    font-display: $font-display;
    src: local($font-name + ' ' + $font-weight),
      url($font-path + $font-weight + '.woff2') format('woff2'),
      // /* Modern Browsers */
      url($font-path + $font-weight + '.woff') format('woff'),
      // /* Modern Browsers */
      url($font-path + $font-weight + '.ttf') format('truetype'),
      // /* Safari, Android, iOS */
      url($font-path + $font-weight + '.eot?#iefix') format('embedded-opentype'),
      // /* IE6-IE8 */
      url($font-path + $font-weight + '.eot'),
      ///* IE9 Compat Modes */
      // url($font-path + $font-weight + '.otf') format('opentype'),
      // /* Open Type Font */
      url($font-path + $font-weight + '.svg') format('svg');
      // /* Legacy iOS */
  }
}

/*
 * Add fonts
 */
@include font-face(get-font('primary'), get-font('primary', 'src'), 'Light');
@include font-face(get-font('primary'), get-font('primary', 'src'), 'Regular');
@include font-face(get-font('primary'), get-font('primary', 'src'), 'Medium');
@include font-face(get-font('primary'), get-font('primary', 'src'), 'Bold');
@include font-face(get-font('primary'), get-font('primary', 'src'), 'Black');

@include font-face(get-font('secondary'), get-font('secondary', 'src'), 'Light');
@include font-face(get-font('secondary'), get-font('secondary', 'src'), 'Regular');
@include font-face(get-font('secondary'), get-font('secondary', 'src'), 'Medium');
@include font-face(get-font('secondary'), get-font('secondary', 'src'), 'SemiBold');
@include font-face(get-font('secondary'), get-font('secondary', 'src'), 'Bold');
@include font-face(get-font('secondary'), get-font('secondary', 'src'), 'Black');
