/**
 *
 * Note: See _margin.scss to review margin styles
 */
.mat-drawer-container,
section.page__content {
  background-color: $white !important;
}

.mat-drawer-content.mat-sidenav-content {
  max-width: 100vw;
}

.page__content {
  // General margin top
  @extend .section--margin-top;
  padding-bottom: calc-size(map-get($spacing, '500'));
  
  @include set-breakpoint('small') {
    padding-bottom: calc-size(map-get($spacing, '900'));
  }

  display: flex;
  flex-direction: column;


  &--align-center {
    justify-content: center;
    align-items: center;
  }
}

.foundation-page,
.subscription-page {
  &__image {
    display: block;
    max-width: 100px;
    margin-bottom: calc-size(map-get($spacing, '200'));
  }

  &__buttons {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    gap: calc-size(map-get($spacing, '300'));

    width: 100%;

    ui-button {
      min-width: fit-content;
      width: 100%;
    }

    &__container {
      background-color: gray;
    }

    button.button {
      min-width: 240px;
      width: 100%;
      &--cta,
      &--disabled {
        font-weight: 500;
      }
    }
  }

  @include set-breakpoint('xx-small') {
    &__buttons {
      ui-button {
        min-width: unset;
        width: unset;
      }

      button.button {
        width: 270px;
      }

      &--step-documents {
        button.button {
          padding-inline: 0;
        }
      }
    }
  }

  @include set-breakpoint('x-small') {
    &__buttons {
      ui-button {
        min-width: unset;
        width: unset;
      }

      button.button {
        width: 280px;
      }
    }
  }

  @include set-breakpoint('small') {
    &__buttons {
      flex-direction: row;
      align-items: center;
      justify-content: center;

      gap: calc-size(map-get($spacing, '600'));
      // margin-top: calc-size(map-get($spacing, '650'));

      button.button {
        width: 100%;
        max-width: unset;
        flex: unset;
      }

      /* two items */
      ui-button:first-child:nth-last-child(2),
      ui-button:first-child:nth-last-child(2) ~ ui-button {
        flex: 1 1 0;
        max-width: 320px;
      }

      &--step-documents {
        button.button {
          padding-inline: map-get($spacing , '400');
        }
      }

      // button.button {
      //   min-width: unset;
      //   width: 100%;
      // }
    }
  }
}

.subscription-page,
.foundation-page {
  &__buttons {
    margin-top: calc-size(map-get($spacing, '400'));
  }
  @include set-breakpoint('small') {
    &__buttons {
      margin-top: calc-size(map-get($spacing, '650'));
    }
  }
}

.foundation-page {
  &__buttons {
    &--include-error {
      margin-top: calc(calc-size(map-get($spacing, '400')) - $error_height);
    }
  }
  @include set-breakpoint('small') {
    &__buttons {
      &--include-error {
        margin-top: calc(calc-size(map-get($spacing, '650')) - $error_height);
      }
    }
  }
}

.account-confirmation-page .foundation-page__buttons {
  button.button {
    min-width: fit-content;
    width: 100%;

    @include set-breakpoint('xx-small') {
      min-width: 270px;
    }
    
    @include set-breakpoint('x-small') {
      min-width: 310px;
    }
  }

  @include set-breakpoint('small') {
    ui-button:first-child:nth-last-child(2),
    ui-button:first-child:nth-last-child(2) ~ ui-button {
      flex: unset;
      min-width: fit-content;
      width: fit-content;
      button.button {
        min-width: 230px;
      }
    }
  }
}

// .foundation-page {
//   &__buttons {
//     // Substract error field height
//     margin-top: calc(calc-size(map-get($spacing, '400')) - $error_height);
//     .stepper-customer-info__form.step-personal & {
//       margin-top: calc(calc-size(map-get($spacing, '400')));
//     }
//   }

//   @include set-breakpoint('small') {
//     &__buttons {
//       margin-top: calc(calc-size(map-get($spacing, '650')) - $error_height);

//       .stepper-customer-info__form.step-personal & {
//         margin-top: calc(calc-size(map-get($spacing, '650')));
//       }

//       button.button {
//         min-width: 280px;
//       }
//     }
//   }
// }

.references-page {
  .markdown {
    padding-bottom: calc-size(map-get($spacing, '400'));
  }
}

.stepper-customer-info__header {
  margin-bottom: calc-size(map-get($spacing, '500'));
  @include set-breakpoint('small') {
    margin-bottom: calc-size(map-get($spacing, '650'));
  }
}

.step-documents {
  h2 {
    margin-bottom: calc-size(map-get($spacing, '200'));
  }
}

/////////////////////////////
/// Head
/// Description of content
/// Ex. markdown text
/////////////////////////////
/// Body
/// Main content
/// Ex. Form
/////////////////////////////
/// Clousure
/// Additional content
/// Ex. Disclaimers
/////////////////////////////
/// Actions
/// Call to actions
/// Ex. Buttons
/////////////////////////////

.foundation-page {
  .foundation-page__head {
    margin-bottom: calc-size(map-get($spacing, '600'));
  }
}
