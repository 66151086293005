/**
 * General Markdown styles
 */
 .markdown {
  display: block;
}

/**
 * Markdown styles inside .static-page__content
 */

.markdown {
  ul,
  ol {
    line-height: 1.5;
  }
  .static-page .static-page__content &,
  .stepper-customer-info__header & {
    text-align: justify;

    h1 {
      font-family: get-font('secondary');
      font-weight: 600;
      font-size: 32px;
      line-height: calc-size(54px);
      text-align: center;
      color: $black;

      font-size: calc-size(get-font-size('xxx-small', 'display2'));
      @include set-breakpoint('x-small') {
        font-size: calc-size(get-font-size('x-small', 'display2'));
      }
      @include set-breakpoint('small') {
        font-size: calc-size(get-font-size('small', 'display2'));
      }
      //
      line-height: calc-size(52px);
      // margin-bottom: calc-size(20px);
      padding: 0 calc-size(20px);
    }

    h2 {
      font-family: get-font('secondary');
      font-weight: 500;
      color: $dark-blue;
      font-size: calc-size(get-font-size('xxx-small', 'display3'));
      @include set-breakpoint('small') {
        font-size: calc-size(get-font-size('small', 'display3'));
      }
      //
      line-height: calc-size(45px);
      // margin-bottom: calc-size(20px);
    }

    a {
      color: $dark-blue;
      &:hover {
        opacity: 0.5;
      }
      //
    }

    h1,
    h2,
    ul,
    ol,
    p:not(:last-child) {
      margin-bottom: calc-size(18px);

      @include set-breakpoint('xx-small') {
        margin-bottom: calc-size(20px);
      }

      @include set-breakpoint('small') {
        margin-bottom: calc-size(30px);
      }
    }

    span.list {
      &--apha > ul,
      &--apha > ol {
        list-style-type: lower-alpha;
      }

      &--roman > ul,
      &--roman > ol {
        list-style-type: lower-roman;
      }
    }
  }

  // .static-page--terms .static-page__content & {
  //   > * {
  //     color: $gray;
  //   }

  //   a {
  //     color: $dark-blue;
  //     text-decoration: none;
  //     &:hover {
  //       text-decoration: underline;
  //     }
  //     //
  //   }

  //   h2 {
  //     text-align: start;
  //   }

  //   ul {
  //     padding-left: 2rem;
  //   }
  // }

  .static-page--page .static-page__content & {
    text-align: center;
  }
}

/**
 * Markdown styles inside .nav .nav__help
 */
.markdown {
  .nav .nav__help & {
    margin: calc-size(map-get($spacing, '400')) 0;

    p {
      font-family: get-font('secondary');
      font-weight: 400;
      font-size: calc-size(get-font-size('xxx-small', 'variant2'));
      @include set-breakpoint('small') {
        font-size: calc-size(get-font-size('small', 'variant2'));
      }
      //
      padding: calc-size(map-get($spacing, '75'));
      font-size: calc-size(22px);
      text-align: center;
      color: $white;
    }

    p:nth-child(2) {
      color: $brand-blue;
      //
    }

    p:last-child,
    a {
      display: flex;
      align-items: center;
      font-size: calc-size(32px);

      a {
        color: $dark-blue;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        //
        font-family: inherit;
        font-weight: 600;
        color: inherit;
      }

      img {
        max-width: fit-content;
        max-height: fit-content;
        padding-right: calc-size(map-get($spacing, '100'));
      }
    }

    @include set-breakpoint($sidenav-breakpoint) {
      p:last-child {
        font-size: calc-size(30px);
      }
    }
  }
}

/**
 * Foundation page styles
 */
.markdown {
  .foundation-page .foundation-page__section1 &,
  .subscription-page .subscription-page__section1 &,
  .subscription-page .subscription-page__section2 & {
    & > * {
      text-align: center;
      margin: 0 auto;
      max-width: calc-size(800px);
    }

    h1 {
      font-family: get-font('secondary');
      font-weight: 600;
      line-height: calc-size(45px);
      color: $dark-blue;
      font-size: calc-size(get-font-size('xxx-small', 'display4'));
      @include set-breakpoint('small') {
        font-size: calc-size(get-font-size('small', 'display4'));
      }
      @include set-breakpoint('xxx-large') {
        font-size: calc-size(get-font-size('xxx-large', 'display4'));
      }
      //
      padding-bottom: calc-size(map-get($spacing, '400'));
    }

    p {
      color: $black;
    }
    a {
      color: $dark-blue;
      &:hover {
        opacity: 0.5;
      }
      //
    }
  }

  .subscription-page .subscription-page__section1 & {
    h1 {
      font-weight: 400;
    }
  }

  @include set-breakpoint('small', max) {
    .foundation-page .foundation-page__section1 &,
    .subscription-page .subscription-page__section1 &,
    .subscription-page .subscription-page__section2 & {
      h1 {
        padding-bottom: calc-size(map-get($spacing, '200'));
      }
    }
  }

  .foundation-page .foundation-page__section1 &,
  .subscription-page .subscription-page__section1 & {
    p {
      font-family: get-font('secondary');
      font-weight: 400;
      font-size: calc-size(get-font-size('xxx-small', 'variant2'));
      @include set-breakpoint('small') {
        font-size: calc-size(get-font-size('small', 'variant2'));
      }
      //
      line-height: calc-size(35px);
    }
  }

  .foundation-page .foundation-page__section2 &,
  .subscription-page .subscription-page__section2 & {
    p,
    a {
      font-family: get-font('secondary');
      font-weight: 400;
      font-size: calc-size(get-font-size('xxx-small', 'variant1'));
      @include set-breakpoint('small') {
        font-size: calc-size(get-font-size('small', 'variant1'));
      }
      //
      line-height: calc-size(35px);
    }
  }

  .programs-page .foundation-page__section1 &,
  .programs-page .foundation-page__section2 & {
    h2 {
      margin-bottom: calc-size(map-get($spacing, '500'));
      font-family: get-font('secondary');
      font-weight: 600;
      line-height: calc-size(45px);
      color: $dark-blue;
      font-size: calc-size(get-font-size('xxx-small', 'display4'));
      @include set-breakpoint('small') {
        font-size: calc-size(get-font-size('small', 'display4'));
      }
      @include set-breakpoint('xxx-large') {
        font-size: calc-size(get-font-size('xxx-large', 'display4'));
      }
      //
      font-weight: 300;
      color: $dark-blue;

      @include set-breakpoint('small', max) {
        margin-bottom: calc-size(map-get($spacing, '200'));
      }
    }

    p:not(:last-child) {
      margin-bottom: calc-size(map-get($spacing, '400'));
    }

    a {
      color: $dark-blue;
      &:hover {
        opacity: 0.5;
      }
      //
    }

    .text-gray {
      p {
        color: $gray;
      }
    }

    .text-weight-light {
      p {
        font-weight: 300;
      }
    }
  }

  @include set-breakpoint('small', max) {
    .programs-page .foundation-page__section1 &,
    .programs-page .foundation-page__section2 & {
      h2 {
        margin-bottom: calc-size(map-get($spacing, '200'));
      }
      p {
        padding-bottom: calc-size(map-get($spacing, '200'));
      }
    }
  }

  .programs-page .foundation-page__section2 & {
    p {
      text-align: center;
    }
  }
}

/**
 * STEPPER
 */
.markdown {
  .stepper-customer-info__header & {
    // max-width: calc-size(800px);
    // margin: 0 auto;
    text-align: center;
    h1 {
      margin-bottom: calc-size(map-get($spacing, '100'));
    }
    h2 {
      font-weight: 600;
      margin-bottom: calc-size(map-get($spacing, '200'));
    }
    p {
      font-family: get-font('secondary');
      font-weight: 400;
      font-size: calc-size(get-font-size('xxx-small', 'variant2'));
      @include set-breakpoint('small') {
        font-size: calc-size(get-font-size('small', 'variant2'));
      }
      //
    }
  }
}

/**
 * STEPPER
 */
.markdown {
  .heading1-blue & {
    h1 {
      font-family: get-font('secondary');
      font-weight: 600;
      line-height: calc-size(45px);
      color: $dark-blue;
      font-size: calc-size(get-font-size('xxx-small', 'display4'));
      @include set-breakpoint('small') {
        font-size: calc-size(get-font-size('small', 'display4'));
      }
      @include set-breakpoint('xxx-large') {
        font-size: calc-size(get-font-size('xxx-large', 'display4'));
      }
      //
      text-align: center;
    }
  }
  .text-normal & {
    p {
      margin-bottom: calc-size(map-get($spacing, '200'));
    }
    p,
    a {
      font-family: get-font('secondary');
      font-weight: 400;
      font-size: calc-size(get-font-size('xxx-small', 'variant1'));
      @include set-breakpoint('small') {
        font-size: calc-size(get-font-size('small', 'variant1'));
      }
      //
    }
    a {
      color: $dark-blue;
      &:hover {
        opacity: 0.5;
      }
      //
    }
  }
}

.markdown {
  .scroll-container & {
    & > *,
    // lo,
    li {
      margin-bottom: calc-size(map-get($spacing, '400'));
    }

    img {
      min-width: 200px;
      width: 60%;
      max-width: 300px;

      margin: auto;
      display: block;
    }

    @include set-breakpoint('small', max) {
      img {
        margin: calc-size(map-get($spacing, '100')) auto;
      }
    }

    h3 {
      text-align: center;
      margin: calc-size(map-get($spacing, '500')) 0;
    }

    p,
    table,
    li {
      color: $gray;
      line-height: 28px;
    }

    p[align='center'] {
      margin-left: clamp(
        calc-size(map-get($spacing, '400')),
        20%,
        calc-size(map-get($spacing, '800'))
      );
      margin-right: clamp(
        calc-size(map-get($spacing, '400')),
        20%,
        calc-size(map-get($spacing, '800'))
      );
    }

    /**
     * List styles
     */
    ol {
      padding-left: calc-size(map-get($spacing, '200'));
      // list-style-position: outside;
      margin-bottom: calc-size(map-get($spacing, '400'));
      li {
        margin-bottom: calc-size(map-get($spacing, '200'));
      }
    }

    .nested-list {
      li {
        list-style-position: inside;
      }
      // list-style: none;
      // counter-reset: item;

      // & > li {
      //   display: block;
      //   position: relative;
      //   counter-increment: item;
      // }

      // & > li::before {
      //   content: counters(item, ".") " ";
      //   position: absolute;
      //   left: -2em;
      // }

      // & > li ol {
      //   list-style-type: none;
      //   counter-reset: subitem;
      //   margin-top: 0.5em;
      //   margin-bottom: 1em;
      // }

      // & > li ol > li {
      //   display: block;
      //   position: relative;
      //   counter-increment: subitem;
      // }

      // & > li ol > li::before {
      //   content: counter(item) "." counters(subitem, ".") " ";
      //   position: absolute;
      //   left: -3em; /* Adjust the value as needed */
      // }
    }

    @mixin resetListStyles() {
      // & > li {
      //   display: inherit;
      //   position: inherit;
      //   counter-increment: inherit;
      // }

      // & > li::before {
      //   content: inherit;
      //   position: inherit;
      //   left: 0;
      // }

      // & > li ol {
      //   list-style-type: none;
      //   counter-reset: subitem;
      //   margin-top: 0.5em;
      //   margin-bottom: 1em;
      // }

      // & > li ol > li {
      //   display: block;
      //   position: relative;
      //   counter-increment: subitem;
      // }

      // & > li ol > li::before {
      //   content: counter(item) "." counters(subitem, ".") " ";
      //   position: absolute;
      //   left: -3em; /* Adjust the value as needed */
      // }
    }

    .list-type-upper-roman ol {
      @include resetListStyles();
      list-style: upper-roman !important;
      list-style-type: upper-roman !important;
    }

    .list-type-lower-alpha ol {
      @include resetListStyles();
      list-style: lower-alpha !important;
      list-style-type: lower-alpha !important;
    }

    /**
     * Table
     */
    table {
      width: 90%;
      margin: 0 auto;
      border: 1px solid #dee2e6;
      border-collapse: collapse;
      box-sizing: border-box;

      td {
        width: 50%;
      }

      td,
      th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
        vertical-align: top;
      }

      tr {
        min-height: calc-size(40px);
        height: max-content;
      }

      tr:nth-child(even) {
        background-color: #dddddd;
      }

      .user-data-table {
        tr:nth-child(even) {
          background-color: unset;
        }
      }
    }
  }
}

/**
 * Section
 */

.markdown {
  .card &,
  .section__content & {
    h2,
    h3 {
      text-transform: uppercase;
      max-width: 800px;
      margin: 0 auto;
    }
    h2,
    h3,
    p {
      text-align: center;
    }
    h2 {
      margin-bottom: calc-size(map-get($spacing, '200'));
    }
    h3 {
      margin-bottom: calc-size(map-get($spacing, '200'));
    }
    .text-variant2 p {
      font-family: get-font('secondary');
      font-weight: 400;
      font-size: calc-size(get-font-size('xxx-small', 'variant2'));
      @include set-breakpoint('small') {
        font-size: calc-size(get-font-size('small', 'variant2'));
      }
      //
      font-weight: 700;
    }
    .text-blue p {
      color: $brand-blue;
      // text-align: center;
    }
  }

  .section--everyone-loves-us &,
  .section--why-choose-fundo & {
    p {
      text-align: left;
    }

    .text-blue p {
      text-align: center;
    }

    @include set-breakpoint('small') {
      .text-blue p {
        text-align: left;
      }
    }
  }

  .section--minimum-eligibility & {
    h2 {
      max-width: 1200px;
      margin-bottom: calc-size(map-get($spacing, '300'));
    }
  }

  .section--contact-info & {
    h2 {
      text-align: left;
    }

    @include set-breakpoint($home-contact-breakpoint, max) {
      h2 {
        text-align: center;
      }
    }
  }

  .section--contact-form & {
    p {
      font-family: get-font('secondary');
    }
  }
}

.markdown {
  .section--minimum-eligibility & {
    @include set-breakpoint('small') {
      h2 {
        margin-bottom: calc-size(map-get($spacing, '400'));
      }
    }
  }
}

/**
 * DIALOGS
 */

.markdown {
  .dialog__text-content & {
    text-align: center;
    h2 {
      color: $dark-blue;
      font-weight: 400;
      font-size: calc-size(36px);
      margin-bottom: calc-size(map-get($spacing, '300'));
    }
    p,
    a {
      font-size: calc-size(20px);
      line-height: calc-size(28px);
    }
    p {
      color: $black;
      margin-bottom: calc-size(map-get($spacing, '200'));
    }
    p:last-child {
      margin-bottom: 0;
    }
    a {
      color: $dark-blue;
    }
  }
  .content__docs & {
    text-align: justify;
    h2 {
      text-align: center;
    }
  }
}

.static-page {
  .static-page__content {
    .markdown {
      h2 {
        margin-bottom: calc-size(map-get($spacing, '400'));
        font-weight: 400;
      }

      h3 {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: calc-size(map-get($spacing, '200'));
      }
    }
  }
}

.question__markdown {
  .markdown {
    p {
      text-align: justify;
      margin-bottom: calc-size(map-get($spacing, '200'));
    }
  }
}
