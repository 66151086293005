
.section {
  .section__content {
    padding-top: map-get($spacing, '400');
    padding-bottom: map-get($spacing, '300');
  }
}

@include set-breakpoint('x-small') {
  .section {
    border-radius: map-get($round-corners, 'section');
    .section__content {
      padding-bottom: map-get($spacing, '500');
    }
  }
}

@include set-breakpoint('small') {
  .section {
    .section__content {
      padding-top: map-get($spacing, '700');
      padding-bottom: map-get($spacing, '900');
      .section__text {
        margin-bottom: calc-size(map-get($spacing, '400'));
      }
    }
  }
}

// @include set-breakpoint('x-large') {
//   .section {
//     .section__content {
//       padding-top: map-get($spacing, '700');
//       padding-bottom: map-get($spacing, '900');
//       .section__text {
//         margin-bottom: calc-size(map-get($spacing, '400'));
//       }
//     }
//   }
// }

@include set-breakpoint('x-high-quality') {
  .section {
    .section__content {
      padding-bottom: map-get($spacing, '1000');
    }
  }
}

/**
 * Section icons
 */
.section.section--minimum-eligibility {
  .section__content {
    article {
      ui-icon {
        .mat-icon {
          height: 35px;
          width: 35px;
        }
      }

      // @include set-breakpoint('x-large') {
      //   ui-icon {
      //     .mat-icon {
      //       height: 24px;
      //       width: 24px;
      //     }
      //   }
      // }
    }
  }
}

.section--contact-info,
.section--contact-form {
  .section__content {
    padding-bottom: calc-size(map-get($spacing, '800'));
    gap: calc-size(map-get($spacing, '300'));
  }

  @include set-breakpoint('small', max) {
    .section__content {
      padding: calc-size(map-get($spacing, '700')) 0;
    }
  }

  @include set-breakpoint('x-small', max) {
    .section__content {
      padding: calc-size(map-get($spacing, '500')) 0;
    }
  }
}

.section-group--centered {
  .section--contact-info,
  .section--contact-form {
    .section__content {
      margin: 0;
    }
  }
}
