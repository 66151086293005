/* 
 * Round Corners
 */
$round-corners: (
  'small': 4px,
  'medium': 8px,
  'large': 16px,
  'rounded': 80px,
  'card': 12px,
  'section': 36px,
) !default;
