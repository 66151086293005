/*
 * Default font base
 * This value is used to calc dinamically font sizes with calc-size function.
 * See: @ui/core/tools/_functions.scss
 */
$font-base: 16px;

/*
 * Font sizes given specific breakpoints
 */
$font-sizes: (
  'xxx-small': (
    display1: 32px,
    display2: 32px,
    display3: 30px,
    display4: 30px,
    h1: 32px,
    h2: 22px,
    h3: 20px,
    h4: 15px,
    text: 15px,
    variant1: 18px,
    variant2: 18px,
    caption: 12px,
    button: 15px,
    stepper: 20px,
    header: 20px,
    footer: 15px
  ),
  'xx-small': (
    display1: 32px,
    display2: 32px,
    display3: 30px,
    display4: 30px,
    h1: 32px,
    h2: 22px,
    h3: 20px,
    h4: 15px,
    text: 15px,
    variant1: 18px,
    variant2: 18px,
    caption: 12px,
    button: 15px,
    stepper: 20px,
    header: 20px,
    footer: 15px
  ),
  'x-small': (
    display1: 32px,
    display2: 36px,
    display3: 30px,
    display4: 30px,
    h1: 32px,
    h2: 22px,
    h3: 20px,
    h4: 15px,
    text: 15px,
    variant1: 18px,
    variant2: 18px,
    caption: 12px,
    button: 15px,
    stepper: 20px,
    header: 22px,
    footer: 15px
  ),
  'small': (
    display1: 64px,
    display2: 42px,
    display3: 36px,
    display4: 36px,
    h1: 64px,
    h2: 40px,
    h3: 24px,
    h4: 20px,
    text: 18px,
    variant1: 20px,
    variant2: 24px,
    caption: 15px,
    button: 32px,
    stepper: 20px,
    header: 24px,
    footer: 16px
  ),
  'x-large': (
    display1: 72px,
    display2: 42px,
    display3: 36px,
    display4: 36px,
    h1: 72px,
    h2: 42px,
    h3: 24px,
    h4: 20px,
    text: 18px,
    variant1: 20px,
    variant2: 24px,
    caption: 15px,
    button: 32px,
    stepper: 18px,
    header: 24px,
    footer: 16px
  ),
  'xxx-large': (
    display1: 72px,
    display2: 42px,
    display3: 36px,
    display4: 42px,
    h1: 72px,
    h2: 42px,
    h3: 24px,
    h4: 20px,
    text: 18px,
    variant1: 20px,
    variant2: 24px,
    caption: 15px,
    button: 32px,
    stepper: 18px,
    header: 18px,
    footer: 16px
  ),
  'x-high-quality': (
    display1: 72px,
    display2: 42px,
    display3: 36px,
    display4: 42px,
    h1: 72px,
    h2: 42px,
    h3: 24px,
    h4: 20px,
    text: 18px,
    variant1: 20px,
    variant2: 24px,
    caption: 15px,
    button: 36px,
    stepper: 18px,
    header: 18px,
    footer: 16px
  ),
) !default;

/*
 * Fonts
 *
 * Definition of app fonts withits own variables.
 * Fonts name should match with file's name.
 */
 $fonts: (
  'primary': (
    'src': '/assets/fonts/Roboto/Roboto-',
    'family': 'Roboto',
    'default': 'Roboto'
  ),
  'secondary': (
    'src': '/assets/fonts/Outfit/Outfit-',
    'family': 'Outfit', 
    'default': 'Outfit'
  ),
  'default': sans-serif,
) !default;

/*
 * Fonts weight
 *
 * 
 */
$fonts-weight: (
  'Thin': 100,
  'ExtraLight': 200,
  'Light': 300,
  'Regular': 400,
  'Medium': 500,
  'SemiBold': 600,
  'Bold': 700,
  'ExtraBold': 700,
  'Black': 900,
  'default': 400,
) !default;
