/* Used with preprocessors and contain font, colors definitions, etc. */
@import 'breakpoints';
@import 'colors';
@import 'fonts';
@import 'z-index';
@import 'spacing';
@import 'round-corners';
@import 'shadow';
@import 'layout';

/**
 * Button's variables
 */
$button-apply-now-height-small: 70px;
$button-apply-now-height-large: 100px;

$max-content-width: 1240px;
