/*
 * Colors
 */

// Global
$blue-500: #0e90e4;
$blue-700: #0d7bc5;

$red-400: #ee2038;
$red-500: #d01027;

$green-500: #24993f;
$green-700: #1c7831;

$gray-100: #eff0f6;
$gray-200: #f7fcff;
$gray-250: #f0f0f0;
$gray-275: #d6d6d6;
$gray-300: #aab2c5;
$gray-400: #8e8e8e;
$gray-500: #757575;
$gray-600: #6b6b6b;

$white: #ffffff;
$black: #0c0d14;

// Aliases
$brand-blue: $blue-500;
$dark-blue: $blue-700;

$red: $red-500;

$green: $green-500;
$dark-green: $green-700;

$gray: $gray-500;
$gray-input-disabled: rgba($gray-600, 0.1);

// Colors by component
$light-theme: (
  'background': (
    'primary': $white,
  ),
  'font': (
    'primary': $black,
  ),
  'button': (
    'primary': $gray,
  ),
) !default;

$dark-theme: (
  'background': (
    'primary': $black,
  ),
  'font': (
    'primary': $white,
  ),
  'button': (
    'primary': $gray,
  ),
) !default;
