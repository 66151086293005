/**
 * Mixin for creating media queries based on the breakpoins defined
 */
@mixin set-breakpoint($breakpoint, $limit: min, $adjust: 0) {
  @if map-has-key($breakpoints, $breakpoint) {
    $value: map_get($breakpoints, $breakpoint) + $adjust;
    // Base styles on xxx-small size
    // This styles will apply in the range from 0 to xx-small
    @if $breakpoint == 'xxx-small' {
      @content;
    } @else {
      @media screen and (#{$limit}-width: $value) {
        @content;
      }
    }
  } @else {
    @warn 'Breakpoint #{$value} not found';
  }
}

/**
 * Mixin to add content to the placeholder with compatibility for various browsers
 */
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
