$font-size: 18px;

.toggle-group__container {
  .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
    opacity: 0;
  }

  .mat-button-toggle-group {
    font-size: $font-size;
    flex-wrap: nowrap;
    gap: calc-size(map-get($spacing, '200'));
    margin-bottom: calc-size(map-get($spacing, '200'));
    
    border: 0;
    overflow: visible;
    box-sizing: content-box;
    
    width: 100%;
    padding: 10px;
    margin: -10px;

    &.mat-button-toggle-group-appearance-standard {
      .mat-button-toggle,
      .mat-button-toggle + .mat-button-toggle {
        flex-grow: 1;
        white-space: normal;
        color: $gray;
        border: 1px solid $black;
        border-radius: map-get($round-corners, 'rounded');
        box-shadow: 2px 4px 10px rgba($gray-275, 0.25);
      }

      .mat-button-toggle-appearance-standard {
        width: 100%;

        &.cdk-focused.cdk-key-board-focused,
        &:hover  {
          border: 1px solid $dark-blue;
          box-shadow: 0px 4px 8px 3px rgba($brand-blue, 0.15);
        }

        &:active {
          background-color: $dark-green;
          border-color: $dark-green;
          color: $white;
        }

        &:disabled {
          background-color: $gray-250;
          border-color: $gray-250;
          color: $gray-600;
        }

        &.mat-button-toggle.mat-button-toggle-checked {
          background-color: $green;
          border-color: $green;
          color: $white;
        }

        .mat-button-toggle-label-content {
          line-height: 1.5;
          padding: calc-size(map-get($spacing, '150')) 0;
        }
      }
    }
  }

  @include set-breakpoint('x-small', max) {
    .mat-button-toggle-group {
      flex-wrap: wrap;

      .mat-button-toggle {
        margin-bottom: 0;
      }
    }
  }

  @include set-breakpoint('small') {
    .mat-button-toggle-group {
      width: 100%;
    }
  }
}
