/**
 * Button font sizes given a specific breakpoint
 */
$button-styles: (
  // Default values
  'xxx-small': (
    'nav': (
      'font-size': 18px,
      'line-height': 18px,
      'padding': calc-em(17px, 18px) calc-em(21px, 18px),
    ), //
    'x-small': (
      'font-size': 14px,
      'line-height': 14px,
      'padding': calc-em(10px, 14px) calc-em(32px, 14px) calc-em(10px, 14px) calc-em(32px, 14px),
    ), //
    'small': (
      'font-size': 16px,
      'line-height': 16px,
      'padding': calc-em(22px, 16px) calc-em(30px, 16px),
    ), //
    'medium': (
      'font-size': 18px,
      'line-height': 18px,
      'padding': calc-em(20px, 18px) calc-em(56px, 18px),
    ), //
    'large': (
      'font-size': 22px,
      'line-height': 22px,
      'padding': calc-em(18px, 22px) calc-em(80px, 22px),
    ),
    'x-large': (
      'font-size': 18px,
      'line-height': 18px,
      'padding': calc-em(25px, 18px) calc-em(60px, 18px),
    ),
  ),
  'x-small': (
    'x-large': (
      'font-size': 22px,
      'line-height': 22px,
      'padding': calc-em(23px, 22px) calc-em(38px, 22px),
    ),
  ),
  'small': (
    'nav': (
      'font-size': 18px,
      'line-height': 18px,
      'padding': calc-em(17px, 18px) calc-em(21px, 18px),
    ),
    'small': (
      'font-size': 20px,
      'line-height': 20px,
      'padding': calc-em(22px, 20px) calc-em(36px, 20px),
    ),
    'medium': (
      'font-size': 22px,
      'line-height': 22px,
      'padding': calc-em(22px, 22px) calc-em(40px, 22px),
    ),
    'large': (
      'font-size': 24px,
      'line-height': 24px,
      'padding': calc-em(20px, 24px) calc-em(88px, 24px),
    ),
    'x-large': (
      'font-size': 32px,
      'line-height': 32px,
      'padding': calc-em(33px, 32px) calc-em(46px, 32px),
    ),
  )
)!default;

/**
 * Set button styles for a specific variant
 */
@mixin set-button-style($variant, $button) {
  &--#{$variant} {
    font-size: calc-size(
        map-get($button, 'font-size')
      );
    line-height: calc-size(
      map-get($button, 'line-height')
    );
    padding: map-get($button, 'padding');
  }
}

/**
 * General
 */
button.button {
  display: block;
  font-family: get-font('secondary');
  max-height: fit-content;
  width: fit-content;

  // padding: calc-size(map-get($spacing, '100'))
  //   calc-size(map-get($spacing, '300'));
  border-radius: calc-size(map-get($round-corners, 'rounded'));

  a {
    text-decoration: none;
    color: inherit;
  }
}

/**
 * Font sizes
 */

@each $breakpoint, $sizes in $button-styles {
  button.button {
    @include set-breakpoint($breakpoint) {
      @each $size, $button in map-get($button-styles, $breakpoint) {
        // Set each button's variant
        @include set-button-style($size, $button);
      }
    }
  }
};


/**
* Property values
*/

button.button {
  &--uppercase {
    text-transform: uppercase;
  }

  // &--large {
  //   min-width: 200px;
  // }
  
  &--x-large {
    margin: 0 auto;
    z-index: map-get($z-layers, 'top');
  }

  &--cta {
    font-weight: 600;
    letter-spacing: 0.1em;
  }

  &--link {
    display: inline;
    font-family: get-font('primary');
    font-weight: 400;
    text-decoration: underline;
    font-size: calc-size(get-font-size('xxx-small', 'text'));
    @include set-breakpoint('small') {
      font-size: calc-size(get-font-size('small', 'text'));
    }
    //
    color: $dark-blue;
    &:hover {
      opacity: 0.5;
    }
    //
    background: transparent !important;
    color: $dark-blue !important;
    padding: 0;
    margin: 0;
    text-transform: none;
  }

  &--disabled {
    &.button,
    &.button.mat-flat-button {
      background-color: $gray-250;
      cursor: wait;
      border: none;

      &:hover {
        background-color: $gray-250;
      }
    }

    &.button.button--primary.mat-flat-button {
      .button-upload & {
        color: white;
        background-color: $gray-600;
        cursor: not-allowed;
      }
    }
  }

  /**
   * Keyboard styles
   */
   &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px !important;
  }
}

/**
 * Colors 
 */

button.button {
  &--primary {
    &:hover {
      color: $black;
      background-color: $brand-blue;
    }
    // &:focus-visible {
    //   outline: -webkit-focus-ring-color auto 1px !important;
    // }
  }
}

.mat-button.mat-stroked-button:not(.mat-button-disabled) {
  border: calc-size(2px) solid $dark-blue;
  &:hover {
    border-color: $gray-100;
    color: $dark-blue;
    background-color: $white;
  }
}

.mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
  opacity: 0 !important;
}

/**
 * Spinner
 */

button.button {
  &:has(.spinner) {
    padding: calc-size(5px);
    box-sizing: border-box;
    .mat-button-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

.mat-button.mat-icon-button {
  width: fit-content;
  height: fit-content;
}

.mat-button:has(.icon-menu) {
  height: max-content;
  padding: 5px;
  margin-left: -5px;

  .icon-menu.icon-close {
    padding-left: 10px;
  }
}

.file__button-remove {
  width: fit-content;
  background-color: transparent;
  border: none;
  color: $dark-blue;
  cursor: pointer;
}

// TODO review it works
.nav.nav__apply-now {
  &__apply-now {
    button.button--small {
      padding: 1.11em 2.27em;
    }
  }
}
