.foundation-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc-size(800px);
  margin: 0 auto;

  h2.text-variant2 {
    font-weight: 500;
    margin-top: calc-size(50px);
    margin-bottom: calc-size(20px);
    color: $dark-blue;
  }

  .mat-form-field {
    width: 100%;
    margin: 0 !important;
  }

  // Fields with no validators
  .mat-form-field.--no-error {
    margin-bottom: 22px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba($gray, 0.9);
    font-size: 2px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }

  .checkbox {
    &.--reselling-checkbox {
      margin-bottom: 24px;

      .mat-checkbox-label {
        font-size: calc-size(16px);
        font-weight: 500;
        color: $black;
      }
    }
  }

  .consent-block,
  .checkbox {
    .mat-checkbox-label,
    a {
      font-size: calc-size(14px);
      &--semibold {
        font-weight: 700;
      }

      align-items: flex-start;
      text-align: justify;
      color: $gray-500;
      line-height: 1.25;
    }

    a {
      color: $dark-blue;
      text-decoration: underline;
    }

    .consent-block__text {
      font-size: calc-size(14px);
      color: $gray-500;
      display: block;
      margin-block: 1rem;
    }

    .mat-checkbox-layout {
      white-space: normal;
      align-items: flex-start;
    }

    .mat-checkbox-disabled {
      cursor: not-allowed;
    }

    .mat-checkbox-inner-container {
      margin: 0;
      margin-right: calc-size(map-get($spacing, '100'));
    }

    .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
      background-color: $gray-600;
    }

    .intro-form-page & {
      margin-top: calc(calc-size(map-get($spacing, '400')) - $error_height);
    }
  }

  .consent-block {
    .checkbox {
      &:first-of-type {
        margin-top: calc-size(map-get($spacing, '300'));
      }
    }
  }

  &__captcha-container {
    align-self: center;
  }

  .input {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

    p:first-child {
      margin-bottom: calc-size(map-get($spacing, '100'));
    }

    p:last-child {
      font-size: 0.9em;
      font-weight: 300;
      color: gray;
    }

    .input__wrapper {
      margin-top: 0 !important;

      .mat-form-field-wrapper {
        padding-top: 0px !important;
      }
    }
  }

  .textarea-field-error {
    margin-top: calc-size(map-get($spacing, '200'));
  }

  textarea.mat-input-element {
    resize: none;
  }

  &__element-signed {
    margin-top: calc(calc-size(map-get($spacing, '500')) - $error_height);
    
    line-height: 40px;
    color: $black;
    text-align: center;

    &--error {
      color: $red;
    }
  }
}

.stepper-customer-info {
  &__form {
    p {
      margin-bottom: $error_height;
    }

    & > p:first-child {
      margin-top: 0;
    }

    div p {
      margin-top: calc(calc-size(map-get($spacing, '600')) - $error_height);
      @include set-breakpoint('small') {
        margin-top: calc(calc-size(map-get($spacing, '300')) - $error_height);
      }
    }

    p.input-field-note {
      margin: 0;
    }

    &.step-bank {
      p {
        font-size: calc-size(18px);
        color: $brand-blue;
        text-align: center;

        @include set-breakpoint('xx-small') {
          font-size: calc-size(20px);
        }
      }
    }
  }
}

.signature-form {
  max-width: 1200px;
}

.step-agreement, .step-documents {
  .foundation-form {
    p:has(+ ui-file-upload),
    p:has(+ mat-form-field) {
      margin-bottom: calc-size(map-get($spacing, '100'));
    }
  }
}
