@use 'sass:math';

/**
 * Function for handling font size in pixels and converting it to rem
 */
@function calc-size($px, $base: $font-base) {
  @return math.div($px, $base) * 1rem;
}

/**
 * Function for handling font size in pixels and converting it to em
 */
@function calc-em($px, $base) {
  @return math.div($px, $base) * 1em;
}

/*
 * Function to get the fallback value of an object
 */
 @function get-default($obj, $default: 'default') {
  $value: map-get($obj, $default);
  @if not $value {
    @warn "No default property found for `#{$obj}` map. Property omitted.";
  }
  @return $value;
}

/*
 * Function to get the font from the fonts object
 */
@function get-font($font-name...) {
  $value: map-get($fonts, $font-name...);
  @if not $value {
    @warn "No font property found for `#{$font-name}` in $fonts map. Property omitted.";
  }
  @if type-of($value) == map {
    $value: get-default($value);
  }
  @return $value;
}

/*
 * Function to get the font size from the fonts object
 */
 @function get-font-size($font-size-name...) {
  $value: map-get($font-sizes, $font-size-name...);
  @if not $value {
    @warn "No font-size found for `#{$font-size-name}` in $font-sizes map. Property omitted.";
  }
  @return $value;
}

/*
 * Function to get the font weight from the fonts object
 */
 @function get-font-weight($font-weight-name...) {
  @if not map-get($fonts-weight, $font-weight-name...) {
    @warn "No font found for `#{$font-weight-name}` in $font-weight map. Property omitted.";
  }
  @return map-get($fonts-weight, $font-weight-name...);
}
